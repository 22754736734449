<template>
  <div id="dropdow-button">
    <button @click="toggleDropdown" :class="buttonClass">
      <i v-if="icon" :class="icon" class="button-icon"></i>
      <p class="text">
        {{
          selectedOption
            ? selectText
              ? selectedOption[selectText]
              : selectedOption
            : text
        }}
      </p>
      <span class="icon-arrow" :class="{ opened: open }" />
    </button>
    <transition name="slide">
      <ul class="list" v-if="open" :class="[type]">
        <li
          v-for="option in options"
          :key="selectValue ? option[selectValue] : option"
          :class="[type, size]"
          @click="onSelect(option)"
        >
          <p class="text" :class="{ selected : selectValue
            ? selectedOption[selectValue] === option[selectValue]
            : selectedOption === option }">
            {{ optionText ? option[optionText] : option }}
          </p>
          <span v-if="selectValue
            ? selectedOption[selectValue] === option[selectValue]
            : selectedOption === option" class="icon-pipe" />
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: "DropdownButton",
  props: {
    size: {
      type: String,
      default: "small",
      validator: (val) => ["large", "small"].includes(val),
    },
    type: {
      type: String,
      default: "primary",
      validator: (val) =>
        [
          "primary",
          "secondary",
          "dark-blue",
          "yellow",
          "light-green",
          "black",
        ].includes(val),
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    value: {
      type: [String, Object],
    },
    options: {
      type: Array,
      required: true,
    },
    optionText: {
      type: String,
    },
    selectText: {
      type: String,
    },
    selectValue: {
      type: String,
    },
  },
  components: {},
  data: () => ({
    open: false,
    selectedOption: null,
  }),
  created() {
    this.selectedOption = this.value;
  },
  computed: {
    buttonClass() {
      return `default ${this.size} ${this.type} ${this.open ? "open" : ""}`;
    },
  },
  watch: {
    value() {
      this.selectedOption = this.value;
    },
  },
  methods: {
    toggleDropdown() {
      this.open = !this.open;
    },
    onSelect(option) {
      if (
        this.selectValue
          ? this.selectedOption[this.selectValue] === option[this.selectValue]
          : this.selectedOption === option
      ) {
        return;
      }
      this.selectedOption = option;
      this.open = false;
      this.$emit(
        "input",
        this.selectValue
          ? this.selectedOption[this.selectValue]
          : this.selectedOption
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_fonts.scss";
@import "@/assets/scss/_colors.scss";

$animation-speed: 0.2s;

#dropdow-button {
  display: inline-block;
  position: relative;
}

button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.text {
  display: inline;
  padding-right: 15px;
}

.button-icon {
  font-size: $text-l;
  padding-right: 10px;
  vertical-align: middle;
  display: inline-block;
}

.icon-arrow {
  width: 10px;
  height: 7px;
  background: rgba(36, 42, 85, 0.8);
  display: inline-block;
  mask-image: url("/images/icons/bb-icon_arrow-triangle.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
  transition: $transition-global;

  &.opened {
    background: $link-hover-color;
  }
}

.list {
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  transform-origin: top;
  transition: transform $animation-speed linear;
  overflow: hidden;
  border-radius: 0px 0px 10px 10px;
  z-index: 10;
  li {
    font-size: $text-m;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    &.large {
      height: 60px;
      padding: 0px 30px;
    }
    &.small {
      height: 40px;
      padding: 0px 20px;
    }
  }
}
.slide-enter,
.slide-leave-to {
  transform: scaleY(0);
}

.default {
  border: none;
  font-size: $text-m;
  border-radius: 10px;
  transition: $transition-global;

  &.open {
    background: $primary-purple-dark;
    color: $link-hover-color;
  }
}

button {
  &.large {
    height: 60px;
    padding: 0px 30px;
  }

  &.small {
    height: 40px;
    padding: 0px 20px;
  }
}

.icon-pipe {
  display: inline-block;
  width: 12px;
  height: 10px;
  background: $link-hover-color;
  mask-image: url("/images/icons/bb-icon_pipe.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
}

.primary {
  color: $white;
  background: $primary-purple;

  &:hover {
    background: linear-gradient($primary-purple, #351993);
  }
}

.dark-blue {
  color: $white;
  background: $dark-blue;

  &:hover {
    background: linear-gradient($dark-blue, #361d7e);
  }
}

.yellow {
  color: $black;
  background: $yellow;

  &:hover {
    background: linear-gradient($yellow, #d9af55);
  }
}

.light-green {
  color: $primary-purple;
  background: $light-green;

  &:hover {
    background: linear-gradient($light-green, #0fceab);
  }
}

.black {
  color: $white;
  background: $dark-grey;

  &:hover {
    background: linear-gradient(#3e3e3e, $dark-grey);
  }
}

.secondary {
  color: $dark-blue;
  background: $white;
  border: 1px solid $light-purple;

  @at-root li#{&} {
    border: none;
  }
  @at-root ul#{&} {
    border-radius: 10px;
    border: 1px solid $light-purple;
    box-sizing: border-box;
    top: calc(100% + 26px);
    box-shadow: 0px 4px 8px rgba(75, 46, 170, 0.1);
    padding: 16px 0 20px;

    .text.selected {
      color: $link-hover-color;
    }

    li.small {
      height: auto;
      padding: 0 13px 16px 20px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
</style>
